body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/*Side announcemebt */

.side_ann_container{
  position: fixed;
  display: flex;
  flex-direction: column;
  background-color:#fff;
  opacity: 0.95;
  font-size:13px;
  top: 150px;
  right: 0;
  max-width: 30%;
  max-height: 80vh;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-box-shadow: 0px 0px 10px -6px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 10px -6px rgba(0,0,0,0.75);
  box-shadow: 0px 0px 10px -6px rgba(0,0,0,0.75);
  z-index: 1000;
}

.side_ann_container > #open_side_ann{
  height: 40px;
  width: 40px;
  cursor: pointer;
}

.side_ann_container .head{
  font-size: 22px;
  font-weight: bold;
  padding: 15px;
  margin-bottom: 10px;
  border-bottom: 2px solid rgba(0,0,0,.1);
}
.side_ann_container .ann_text{
  padding: 0 15px 15px 15px;

}
.side_ann_container .ann_text *{
  font-size: 1rem !important;
  line-height: 1.5 !important;
}

.side_ann_container .ann_text .ann_item_title{
   font-size: 1.3rem !important;
   font-weight: bold;
   margin-bottom: 10px;
}
.side_ann_container::-webkit-scrollbar {
  width: 5px;
  
}

.side_ann_container::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 

.side_ann_container::-webkit-scrollbar-thumb {
  background: #888; 
  border-radius: 10px;
}

.side_ann_container::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

.side_ann_container.is_close{
  background-color:#fff;
  width: 60px;
  height: 60px;
  padding: 10px;
  overflow:hidden;
}

.side_ann_container .ann_item_container{
  margin-bottom: 15px;
  padding-bottom: 15px;
}

.side_ann_container .ann_item_container:not(:last-child){
  border-bottom: 2px solid rgba(0,0,0,.1);
}

.jiggle_ann_bill {
  animation: shake 0.5s;
  animation-iteration-count: infinite;
}

@keyframes shake {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
}

.side_ann_container .close_icon{
  cursor: pointer;
  width: 30px;
  height: 30px;
  display: block;
  float: right;   
}

.side_ann_container .close_icon > .line{
  width: 30px;
  height: 2px;
  background-color: #333;
  margin: 9px 0;
  transition: 0.4s;

}

.side_ann_container .close_icon > .line:first-child{
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.side_ann_container .close_icon > .line:last-child{
  -webkit-transform: rotate(45deg) translate(-8px, -8px);
  transform: rotate(45deg) translate(-8px, -8px);
}


@media screen and (max-width:1200px){
  .side_ann_container{
      max-width: 50%;
  }
}

@media screen and (max-width:768px){
  .side_ann_container{
      max-width: 80%;
  }
}

/*End side announcement*/